import React from 'react';
import { View, Text, ImageBackground, TouchableOpacity, Image, Dimensions, ScrollView} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import Menu from "../../menu/menu";
import ReactMarkdown from 'react-markdown'


/*Analitycs*/
import { setCurrentScreen } from 'expo-firebase-analytics';
import * as Analytics from 'expo-firebase-analytics';
import { analytics } from 'firebase';

export default class Premi extends React.Component {

    constructor(props) {
        super(props);
        this.returnMapControl = false
        this.state = props.route.params;
        this.state["showQuizz"] = false;
        this.state["showContinue"] = false;
        this.state["load"] = false;
        this.puntsAconseguits = this.state.puntsAconseguits
        //retard d'un segon per evitar bug de react que carrega la funció que se li passa per render
        this.carregarPremi(this.state.poi.premi)
        this.state.puntsProva = (this.state.puntsProva) ? this.state.puntsProva : 0
        this.state.puntsTotalsAconseguits = parseInt(this.state.currentStateItinerari.puntsTotalsAconseguits) + parseInt(this.state.puntsProva)
        this.puntsTotals = parseInt(localStorage.getItem('puntsTotals')) + parseInt(this.state.puntsProva);
    }

    carregarPremi = (idPremi) => {
        this.state.currentPremi = this.state.projecte.premis.find(e => e.id === idPremi)
    }

    returnMap = async () => {
        if(!this.returnMapControl) {
            this.returnMapControl = true;
            setTimeout(function () {
                this.state.currentStateItinerari.pois.find(e => e.id === this.state.poi.id).done = true
                this.state.currentStateItinerari.currentOrder++
                this.state.currentStateItinerari.puntsTotalsAconseguits = parseInt(this.state.currentStateItinerari.puntsTotalsAconseguits) + parseInt(this.state.puntsProva)
                localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari));
                localStorage.setItem('puntsTotals', this.puntsTotals);
                this.state.navigation.navigate('Mapa', this.state);
            }.bind(this), 300)
        }
    }

    sendToAnalitycs() {
        let nom =this.state.itineraris.find(e => e.id == this.state.currentStateItinerari.id  ).Nom
        Analytics.logEvent(nom+"_"+this.state.currentStateItinerari.currentOrder,{
            screen : nom +"_"+this.state.currentStateItinerari.currentOrder,
            purpose : 'Ha acabat el premi '+ this.state.currentStateItinerari.currentOrder,
        })
   

    }



    render(){
        return(
            <View style={{flex: 1}}>
                <View style={{flex: 15}}>
                    <ImageBackground source={{ uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '06_background_pantalles_generic').imatge_recurs.url }} style={style.imageBackground}>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.26, paddingTop: 10, width: "100%", justifyContent: "center", alignItems: "center", textAlign: "center", alignSelf: 'center'}}>
                            <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'bocata_premi')['imatge_recurs'].url}} resizeMode="contain" style={style.questionBackground}>
                                <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
                                    <Text style={style.score1}>{this.state.recursos.find(e => e.tipus_recurs === 'guanyar')['text_recurs' + this.state.idioma]}</Text>
                                    <Text style={style.score2}>{this.state.puntsProva}/{this.state.currentPremi.punts} {this.state.recursos.find(e => e.tipus_recurs === 'punts')['text_recurs' + this.state.idioma]}</Text>
                                    <Text style={style.score3}>{this.state.recursos.find(e => e.tipus_recurs === 'total_itinerari')['text_recurs' + this.state.idioma]}</Text>
                                    <Text style={style.score4}>{this.state.puntsTotalsAconseguits}/{this.state.puntsItinerari} {this.state.recursos.find(e => e.tipus_recurs === 'punts')['text_recurs' + this.state.idioma]}</Text>
                                </View>
                            </ImageBackground>
                        </View>
                        {/* <ScrollView style={{height: Dimensions.get('screen').height * (13 / 15) * 0.12}}>
                            <View style={[style.descriptionContainer, {justifyContent: "center", textAlign: "center", alignSelf: 'center'}]}>
                                <ReactMarkdown style={style.descripcio}>{(this.state.currentPremi["Nom" +  this.state.idioma]) ?? (this.state.currentPremi["Nom" +  this.state.idioma])}</ReactMarkdown>
                            </View>
                        </ScrollView> */}

                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.28, width: "100%", justifyContent: "center", alignItems: "center", textAlign: "center",alignSelf: 'center'}}>
                            <View style={{height: "100%", width: Dimensions.get('screen').height * (13 / 15) * 0.28, backgroundColor: "#715f4d", alignItems: "center", resizeMode: 'contain', justifyContent: "center", textAlign: "center",borderRadius:5}} >
                                <Image style={{height: Dimensions.get('screen').height * (13 / 15) * 0.27, width: Dimensions.get('screen').height * (13 / 15) * 0.27, resizeMode: 'contain',borderRadius:5}} source={{uri: api.api_url + this.state.currentPremi.Premi.url}} />
                            </View>
                        </View>
                        <ScrollView style={{height: Dimensions.get('screen').height * (13 / 15) * 0.16, marginBottom: 15 , marginTop: 10}}>
                            <View style={[style.descriptionContainer, {justifyContent: "center", textAlign: "center", alignSelf: 'center'}]}>
                                <ReactMarkdown style={style.descripcio}>{(this.state.currentPremi["descripcioPremiDetallat" +  this.state.idioma]) ?? (this.state.currentPremi["descripcioPremiDetallat" +  this.state.idioma])}</ReactMarkdown>
                            </View>
                        </ScrollView>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.09, justifyContent: "flex-start" }}>
                            <TouchableOpacity style={style.containerContinue} onPress={() => {
                                this.sendToAnalitycs()
                                this.returnMap() 
                            }}>
                                <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.10, justifyContent: "center"}} />
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>

        )
    }
}
