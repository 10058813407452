import { StyleSheet, Platform } from 'react-native';
import { Dimensions} from 'react-native';

export default StyleSheet.create({
    container: {
        flex:1,
        flexDirection:'row',
        paddingVertical:10,
    },
    menu4:{
        flex: 0.7,
        alignSelf:'center',
        marginLeft: '7%',
        paddingRight: 20,
        paddingLeft: 20
    },
    menu5:{
        flex:0.25,
        alignSelf:'center',
        paddingRight: 20,
        paddingLeft: 20
    }, 
    menu6:{
        flex:0.2,
        alignSelf:'center',
        marginLeft: '2%',
        paddingRight: 20,
        paddingLeft: 20,
    },
    iconesMenu:{
        width:Dimensions.get('window').width/ 100 *25 ,
        height:Dimensions.get('window').height/ 100 *8,
        alignSelf:'center',resizeMode: 'contain',
    }
  });
