import {
    StyleSheet
} from 'react-native';
import {
    Dimensions
} from "react-native";
import { AutoScrollDirection } from 'react-native-drax';


export default StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: '100%'
    },

    imageBackground: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        borderRadius:5
    },
    difuminat: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        opacity: 0.3,
    },
    text: {
        color: "black",
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10,
    },

    titol: {
        fontFamily: 'OpenSans',
        textAlign: "center",
        paddingTop: 0,
        paddingBottom: 10,
        fontSize: 36,
    },
    textPerfil: {
        fontFamily: 'OpenSans',
        fontSize: 18,
        textAlign: "center",
        color: "#fdfeff",
        fontWeight: "bolder"
    },
    nom: {
        textAlign: "center",
        // fontWeight: "bolder",
        fontSize: 25,
        color: "#7ec102",
        fontFamily: 'OpenSans',
    },
    SecondTitle: {
        color: "#3f3b22",
        fontSize: 20,
        textAlign: "center",
        fontWeight: "bold",

    },
    SecondText: {
        color: "#61983d",
        fontSize: 21,
        textAlign: "center",
        // fontWeight: "bold",

    },
    puntsText: {
        // color: "white",
        textAlign: "center",
        //   color: "black",
        fontWeight: 700,
        fontSize: 17,
        alignSelf: 'center',
        fontFamily: 'OpenSans'
    },
    text1: {
        // color: "white",
        fontSize: 20,
        textAlign: "center",
        color: "white",
        lineHeight: 1,
        fontFamily: 'OpenSans'
    },
    text2: {
        fontSize: 60,
        fontWeight: "bold",
        textAlign: "center",
        // color: "white",
        paddingTop: 0,
        paddingBottom: 5,
        fontFamily: 'OpenSans'
    },
    icona: {
        width: 50,
        height: 50,
        //  marginLeft: 15,
        marginBottom: -15,
        resizeMode: "stretch"
    },
    noAconseguit: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.15,
        width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.15,
        resizeMode: 'contain',
        //alignSelf:'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
        margin: 2

    },
    
    aconseguit: {
        flexDirection: 'row',
        height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.15,
        width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.15,
        resizeMode: 'contain',
        //alignSelf:'center',
        paddingVertical: 20,
        paddingHorizontal: 20,
        margin: 2
    },
    superPremiImg: {
        flexDirection: 'row',
        height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.15,
        width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.45 * 3,
        resizeMode: 'contain',
        paddingVertical: 20,
        paddingHorizontal: 20,
        marginBottom: 10
    },
    imatgeClick: {
        height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.5,
        width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.45,
        alignSelf: 'center',
        resizeMode: 'contain',
    },
    caixaOut: {
        marginHorizontal: '10%',
        borderRadius: 10,
        flex: 1,
        alignContent:'center'
    },
    caixa: {
        maxHeight: "100%",
        width: "100%",
        maxWidth: "100%",
        alignContent:'center',
        alignSelf:'center'
    },
    caixaBorderContenidor: {
        borderWidth: 3,
        borderRadius: 10,
    },
    modalContent: {
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        borderColor: "rgba(0, 0, 0, 0.1)",
        alignSelf: 'center',
    },
    header: {
        color: 'white',
        backgroundColor: '#333',
        textAlign: 'center',
        position: 'relative',
        height: '50',
    },
    //todo s'ha de canviar aquest
    textImatge: {
        fontSize: 16,
        // alignSelf: 'center',
        justifyContent: "center",
        fontWeight: 'bold',
        color:'black',
        paddingTop: 10,
        width:'80%',
        textAlign:'center',
        alignItems:'center',
        alignSelf:'center'
    },

    


////////////////////ESTARNIN//////////////////

    caixetiEstarnin: {
        paddingHorizontal: 1,
        paddingTop: 5,
        paddingBottom: 15,
        borderRadius: 10,
        marginHorizontal: 5
    },
    imatgesEstarnin: {
        backgroundColor: 'white',
        width: 65,
        height: 65,
        paddingHorizontal: 20,
        borderRadius: 10
    },
    imatgesEstarniNoAconseguit: {
        backgroundColor: 'rgba(255, 255,255, 0.7)',
        width: 65,
        height: 65,
        paddingHorizontal: 20,
        borderRadius: 10,
        opacity: 0.8
    },
    rodonaPunts: {
        borderRadius: 300,
        paddingHorizontal: 10,
        paddingVertical: 10,
        backgroundColor: 'red',
        color: "white",
    },

    SobreClick: {
        width: 300,
        height: 300,
        alignSelf: 'center',
        resizeMode: 'contain',
    },
    mineralContainer: {
        height: (Dimensions.get('screen').height * (13 / 15) * 0.17) * 0.7,
        // width: (Dimensions.get('screen').width * (13 / 15) * 0.17) * 0.7,
        width : '30%',
        maxWidth : '30%',
        marginHorizontal: "10%",
        alignSelf: 'center',
        alignItems:'center'
    },
    mineral: {
        resizeMode: 'contain',
        height: "100%",
        width: Dimensions.get('screen').width * 40/250,
    },
    itinerariContainerMain: {
        width: "50%",
        justifyContent: "center",
    },
    itinerariContainer: {
        backgroundColor: "#3f3b22",
        paddingTop: 5,
        paddingBottom: 5,
        width: "90%",
        textAlign: "center",
        alignSelf: 'center',
        marginTop: 7,
        marginBottom: 7,
        marginLeft: 7,
        marginRight: 7,
        borderRadius: 5
    },
    itinerariText: {
        fontSize: 15,
        textAlign: "center",
        fontWeight: "bold",
        width: '100%',
        alignSelf: 'center',
        color: "white",
    },
    modalOutBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    popUp: {
        display: "flex",
        height: "50%",
        width: "70%",
        borderColor: "#a88f40",
        borderStyle: "solid",
        borderWidth: 6
    },
    caixaFinal: {
        display: "flex",
        height: "70%",
        width: "70%",
        // borderColor: "#a88f40",
        borderStyle: "solid",
        // borderWidth: 6,
        // borderRadius:5
    },
    crossBox: {
        position: 'absolute',
        right: 6,
        top: 6
    },
    cross: {
        color: "white",
        fontSize: 20
    },
    crossImage: {
        height: 30,
        width: 30
    },
    popUpTitle: {
        color: "#715f4d",
        fontSize: 28,
        textAlign: "center",
        fontWeight: "bold",
        lineHeight: 28,
        paddingBottom: 28
    },
    popUpSubtitle: {
        color: "#252525",
        fontSize: 20,
        textAlign: "center",
        fontWeight: "600",

    },
    textCopyPlaceholder: {
        fontSize: 26,
        width: '70%',
        height: 40,
        alignSelf: 'center',
        backgroundColor: 'white',
        borderRadius: 1,
        textAlign: 'left',
        fontWeight: 'bold',
        //default
        //borderColor: '#891812',
        //talpó
        color: '#715f4d',
        paddingLeft: 10
    },
    textCopy: {
        fontFamily: 'OpenSans', //=>
        fontSize: 20,
        // color: '#b73b53',
        padding: 5,
        paddingHorizontal:10,
        alignSelf: 'center',
        //previ:
        backgroundColor: 'white',
        //color:'#f2eb7a',
        //talpó
        color:'#2a3209',
        fontWeight: 'bold',
        maxWidth: "70%",
        borderTopLeftRadius:5,
        borderBottomLeftRadius:5,
    },
    boxPerfil :{ 
        flex: 1,
        justifyContent: "center",
        width: "80%",
        maxHeight: "80%",
        borderColor: '#4c472a',
        borderWidth: 8,
        backgroundColor: "#4c472a",
        borderRadius: 5 
    },
    boxPuntsTotals:{
        alignSelf: 'center',
        backgroundColor: "#ffffff",
        fontWeight: 'bold',
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center",
        width: "95%",
        paddingHorizontal: 2,
        height: "100%"
    },
        containerMenu: {
        flex:1,
        flexDirection:'row',
        paddingVertical:10,
    },
    menu6:{
        flex:0.2,
        alignSelf:'center',
        marginLeft: '2%',
        paddingRight: 20,
        paddingLeft: 20
    },

});

/*
  width: Dimensions.get('screen').width /5.6 ,
  height: Dimensions.get('screen').width /6.5 ,
  */


