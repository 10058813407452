import React from 'react';
import {
    View,
    Text,
    ImageBackground,
    Image,
    ScrollView,
    Dimensions,
    TouchableHighlight,
    TouchableOpacity,
    TouchableWithoutFeedback,
    AsyncStorageStatic, TextInput
} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import style from './style';
import Menu from '../menu/menu';
import Modal from "modal-react-native-web";
import api from '../config/api_urls';
import * as WebBrowser from "expo-web-browser";
import * as Clipboard from 'expo-clipboard'
import {postStadistic} from "../../helpers/petitions";

export default class Perfil extends React.Component {
    constructor(props) {
        super(props)
        this.state = props.route.params
        this.state['premis'] = []
        this.state['imatge'] = []
        this.state['postes'] = []
        this.state['joc'] = ""
        this.state['gameList'] = []
        this.state['visible'] = false
        this.state['SobreVisible'] = false
        this.state['imgClick'] = 0
        this.state['jaRenderitzat'] = false
        this.state['popUpSocial'] = false


        this.state['showItinerari'] = false

        if (typeof this.state.nom === 'undefined') {
            this.state['nom'] = '';
        }
        if (typeof this.state.cookies === 'undefined') {
            this.state['cookies'] = '';
        }


        this.carregarDades()
    }

    componentDidMount() {
        this.getGameList();
        this.getNom();

        // this.isCardRended();
        AsyncStorage.setItem('anarCredits', true);
        setTimeout(() => {
            this.getGamePoints()
            console.log(this.state.carregarItinerariPerfil)
            if(this.state.carregarItinerariPerfil) {
                let itin = this.getItinerari(this.state.carregarItinerariPerfil)
                this.seleccionarItinerari(itin)
                this.setState({carregarItinerariPerfil: false})
            }
        }, 50)
    }

    getItinerari(id) {
        return this.state.itineraris.find(it => it.id === id)
    }

    // isCardRended = async () => {
    //   try {
    //     const value = await AsyncStorage.getItem('jaRenderitzat');
    //     if (value !== null) {
    //       this.setState({ jaRenderitzat : value });
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    carregarDades() {
        this.itineraris = (typeof this.state.projecte && typeof this.state.projecte.itineraris) ? this.state.projecte.itineraris : [];
        this.itinerarisTotals = this.itineraris.length
        this.itinerarisCompletats = 0
        this.estatItineraris = []
        this.puntsTotals = localStorage.getItem('puntsTotals')
        this.state["textSocial"] = encodeURI(this.state.projecte['textSocial' + this.state.idioma]).replaceAll('#', '%23')

        for(let itinerari of this.itineraris) {
            let retrievedObject = localStorage.getItem('itinerari' + itinerari.id);
            if (retrievedObject) {
                let estatItinerari = JSON.parse(retrievedObject);
                this.estatItineraris.push(estatItinerari)
                if(estatItinerari.finalitzat)
                    this.itinerarisCompletats++
            }

        }
    }

    getGamePoints = async () => {
        let punts = 0;
        let length = 0;
        let postes = []
        let passedPois = []
        for (let i = 0; i < this.state.gameList.length; i++) {
            const value = await AsyncStorage.getItem('Game' + [this.state.gameList[i]]);
            if (value !== null) {
                //console.log(JSON.parse(value).idioma);
                if (JSON.parse(value).idioma == this.state.idioma) {
                    punts += JSON.parse(value).score;
                    postes += JSON.parse(value).gainedRewardsIds + ",";
                    passedPois += JSON.parse(value).passedPoisIds + ",";
                    let aux = passedPois.split(',')
                    length = aux.length - 1;
                }
            }
        }
        await this.setState({joc: {score: punts, gainedRewardsIds: postes, passedPoisIds: passedPois, pois: length}})
    }

    getGameList = async () => {
        try {
            const value = await AsyncStorage.getItem('Gamelist');
            if (value !== null) {
                this.setState({gameList: value.split(",")});
            }
        } catch (e) {
            console.log(e);
        }
    }
    getNom = async () => {
        try {
            const value = await AsyncStorage.getItem('nom');
            if (value !== null) {
                this.setState({nom: value});
            }
        } catch (e) {
            console.log(e);
        }
    }

    isInRewardsPoints(id) {
        if (this.state.joc.gainedRewardsIds != null && this.state.joc.gainedRewardsIds != undefined) {
            if (this.state.joc.gainedRewardsIds.includes(id)) {
                return true;
            } else return false;
        }
        return false;
    }

    isInPassedPois(id) {
        if (this.state.joc.passedPoisIds != null) {
            return (this.state.joc.passedPoisIds.includes(id))
        } else {
            return (false)
        }
    }

    /***********
     MODAL
     ***********/
    setModalVisible = visible => {
        this.setState({visible});
    };

    setSobreVisible = SobreVisible => {
        this.setState({SobreVisible})
    }

    _renderPremis() {
        if(this.selectedPremis) {
            return this.selectedPremis.map((data, i) => {

                if (data.superPremi) {
                    if (i + 1 == this.state.joc.pois) { //get last
                        return (this.animacioCarta(data, i))
                    } else {
                        return (this._renderSuperPremi(data, i))
                    }

                } else {
                    return (this.renderitzarPremi(data, i))
                }
            })
        }
        else return null
    }

    animacioCarta(data, i) {
        if (!this.state.jaRenderitzat) {
            setTimeout(() => {
                this.setSobreVisible(true);
                this.setState({imgClick: i});
                this.setState({jaRenderitzat: true});
            }, 100)
            return (null)
        } else {
            return (this._renderSuperPremi(data, i))
        }
    }

    seleccionarItinerari(itinerari) {
        this.selectedItinerari = this.state.itineraris.find(it => it.id === itinerari.id)
        let estatSI = this.estatItineraris.find(it => it.id === itinerari.id)
        this.estatSelectedItinerari = (estatSI) ? estatSI : {}

        this.selectedPremis = this.state.projecte.premis.filter(premi => premi.itinerari === itinerari.id)
        try {
            this.puntsTotalsSelectedItinerari = this.selectedPremis.map(premi => premi.punts).reduce((prev, next) => prev + next)
        }
        catch(e) {
            this.puntsTotalsSelectedItinerari = 0
        }
        this.setState({showItinerari: true})

    }


    _renderSuperPremi(data, i) {
        return (
            <View style={{width: "100%", alignContent: 'center', alignItems: 'center'}}>
                <TouchableHighlight
                    style={[style.button]}
                    onPress={async () => {
                        if(this.estatSelectedItinerari.finalitzat) {
                            this.setModalVisible(true);
                            await this.setState({imgClick: data.id})
                        }
                    }}
                >
                    {this.estatSelectedItinerari.finalitzat ?
                        (

                            <Image
                                style={[style.superPremiImg, /*this.isInPassedPois(data['poi_id'+this.state.idioma]) ? null : style.difuminat */]}
                                source={{uri: api.api_url + data.Premi.url}}/>
                        )
                        :
                        (
                            <Image style={style.superPremiImg}
                                source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'superpremi_bloquejat')['imatge_recurs'].url}}
                                />
                        )}
                </TouchableHighlight>
                <Text style={{ alignSelf: 'center', color: "white", fontWeight: 500, fontSize: 10, paddingVertical: 5, paddingRight: 5 }}></Text>
            </View>
        )
    }

    //Renderitzar Premi tant vertical com horitzontal
    renderitzarPremi(data, i) {
        //if(this.state.projecte.PremiVertical){
        let estatPremi = (this.estatSelectedItinerari.pois) ? this.estatSelectedItinerari.pois.find( e => e.id === data.poi) : null
        let aconseguit = (estatPremi) ? estatPremi.done : false

        return (
            <View style={[this.state.projecte.MarcPremis ?
                {
                    backgroundColor: this.state.projecte.ColorMarcPremis,
                    paddingHorizontal: 1,
                    paddingTop: 5,
                    paddingBottom: 15,
                    borderRadius: 10,
                    marginHorizontal: 5,
                    marginVertical:3,
                    
                }
                :
                {
                    height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.15,
                    width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.15, alignContent: 'center',marginHorizontal:5,marginVertical:3}]}>
                <View style={{alignItems: 'center',}}>
                    <TouchableHighlight
                        style={[style.button]}
                        onPress={async() => {
                            if(aconseguit) {
                                this.setModalVisible(true);
                                await this.setState({imgClick: data.id})
                            }
                        }}
                    >
                        {aconseguit ?
                                    <Image style={style.aconseguit} source={{uri: api.api_url + data.Premi.url}}/>
                                    :
                                    <Image style={[style.noAconseguit, {opacity: 0.3}]}
                                           source={{uri: api.api_url + data.Premi.url}}/>
                        }
                    </TouchableHighlight>
                    <Text style={{
                        alignSelf: 'center',
                        color: "white",
                        fontWeight: 500,
                        fontSize: 10,
                        paddingVertical: 5,
                        paddingRight: 5
                    }}></Text>
                </View>
            </View>
        )
    }

    _renderMinerals() {
        let itemsMinerals = []
        if (this.itineraris.length > 0) {
            for (const [index, value] of this.itineraris.entries()) {
                if(value.premi_alternatiu) {
                    let mineral = this.state.premisAlternatius.find(e => e.id === value.premi_alternatiu)
                    let itinerariEstat = this.estatItineraris.find(e => e.id === value.id)
                    let linkImatgeMineral = (itinerariEstat && itinerariEstat.finalitzat) ? mineral.Imatge2.url : mineral.Imatge1.url
                    itemsMinerals.push(
                        <TouchableOpacity onPress={() => {
                            if ((itinerariEstat && itinerariEstat.finalitzat)) {
                                this.state.currentMineralLink = mineral.Link
                                this.props.navigation.navigate('Mineral', this.state)
                            }
                        }} style={style.mineralContainer}>
                            <Image style={style.mineral} source={{uri: api.api_url + linkImatgeMineral}}/>
                        </TouchableOpacity>
                    )
                }
            }
        }
        return itemsMinerals;
    }

    _renderModalPremi() {

        return (
            <Modal
                animationType="slide"
                transparent={true}
                ariaHideApp={false}
                style={{backgroundColor: 'blue'}}
                onBackdropPress={() => {
                    this.setState({visible: false});
                }}
                visible={this.state.visible}
            >
                <View style={{marginTop: 22}}>
                    <TouchableWithoutFeedback onPress={() => {
                        this.setState({visible: false});
                    }}>

                        <View style={this.state.projecte.MidaMaximaPagines ? null : {
                            marginTop: this.state.pop_up_margin_top,
                            maxHeight: this.state.pop_up_height,
                            paddingBottom: Dimensions.get('screen').width * 0.1,
                            marginHorizontal: '10%'
                        }}>
                            <View style={[style.caixaOut, {backgroundColor: this.state.projecte.colorPrincipal2}]}>
                                <View style={[style.caixa, this.state.projecte.BorderContenidor ? style.caixaBorderContenidor : null, {borderColor: this.state.projecte.ColorBorderContenidor}]}>
                                    <View style={[style.caixa, {marginTop: 30}]}>
                                        <Image style={style.imatgeClick} //fdsa
                                               source={this.state.projecte.premis.length != 0 ? {uri: api.api_url + this.state.projecte.premis.find(e => e.id === this.state.imgClick)?.Premi["url"]} : null}/>
                                        <Text style={style.textImatge}>{this.state.projecte.premis.length != 0 ? this.state.projecte.premis.find(e => e.id === this.state.imgClick)?.['Nom' + this.state.idioma] : null}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </Modal>
        )
    }

    _renderModalAnimacioCarta() {
        return (
            <Modal
                animationType="fade"
                transparent={true}
                ariaHideApp={false}
                style={{backgroundColor: 'blue'}}
                onBackdropPress={() => {
                    this.setState({visible: true});
                    this.setState({SobreVisible: false});
                }}
                visible={this.state.SobreVisible}
            >
                <View style={{marginTop: 22}}>
                    <TouchableWithoutFeedback onPress={() => {
                        this.setState({visible: true});
                        this.setState({SobreVisible: false})
                    }}>

                        <View style={this.state.projecte.MidaMaximaPagines ? null : {
                            marginTop: this.state.pop_up_margin_top,
                            maxHeight: this.state.pop_up_height,
                            paddingBottom: Dimensions.get('screen').width * 0.1,
                            marginHorizontal: '10%'
                        }}>
                            <View style={style.caixaOut}>
                                <Image style={style.imatgeClick}
                                       source={{uri: 'https://ferranmaso.com/mapsGeo.checkinColor.jpg'}}/>
                                <Image style={style.SobreClick}
                                       source={{uri: 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Cover_Bahamas_1929_front.jpg'}}/>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </Modal>
        )
    }

    _renderItineraris() {

        let items = []
        if (this.itineraris.length > 0) {
            for (const [index, value] of this.itineraris.entries()) {
                let colorItinerari = "#a88f40"
                let colorTextItinerari = "white"
                let retrievedObject = localStorage.getItem('itinerari' + value.id);
                if (retrievedObject) {
                    retrievedObject = JSON.parse(retrievedObject)
                    if(retrievedObject.finalitzat) {
                        colorItinerari = "#715f4d"
                        colorTextItinerari = "#f7f4d3"
                    }
                    else if(retrievedObject.comencat) {
                        colorItinerari = "#f7f4d3"
                        colorTextItinerari = "#715f4d"
                    }
                }
                if (value.Tipus != "Tipus3") {
                    items.push(
                        <TouchableOpacity style={style.itinerariContainerMain} key={index} onPress={() => {
                            this.seleccionarItinerari(value);
                        }}>
                            <View style={style.itinerariContainer}>
                                <Text style={{fontSize: 14, fontWeight: 'bold', padding: 7}}>
                                    <Text style={style.itinerariText}>{value['Nom'] ? value['Nom'].toUpperCase() : null}</Text>
                                </Text>
                            </View>
                        </TouchableOpacity>
                    )

                }
            }
        }
        return items;
    }

    _renderPremisFromItinerari() {
        let linkImatgeMineral = (this.estatSelectedItinerari && this.estatSelectedItinerari.finalitzat) ? this.selectedItinerari.premi_alternatiu.Imatge2.url : this.selectedItinerari.premi_alternatiu.Imatge1.url

        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: "center", width: "100%"}}>
                <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.18, width: "100%", justifyContent: 'center', alignItems: "center",}}>
                    <View style={style.boxPerfil}>
                        <Text style={[style.nom,{color:'white'}]}>{this.selectedItinerari['Nom' + this.state.idioma]}</Text>
                    </View>
                </View>
                <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.18, width: "100%", display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: 'center'}}>
                    <View style={{width: "90%", height: "90%", justifyContent: "center"}}>
                        <View style={{alignSelf: 'center', backgroundColor: "white", alignItems: "center", justifyContent: "center", width: "90%", height: "100%",borderRadius:5}}>
                            <Text style={style.SecondTitle}>{this.state.recursos.find(e => e.tipus_recurs === 'total_itinerari')['text_recurs' + this.state.idioma]}</Text>
                            <Text style={style.SecondText}>{
                                this.estatSelectedItinerari.puntsTotalsAconseguits ? this.estatSelectedItinerari.puntsTotalsAconseguits : 0
                            }/{this.puntsTotalsSelectedItinerari}</Text>
                        </View>
                    </View>
                </View>
                <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.64, width: "80%", maxWidth: "80%", alignItems: "center", justifyContent: "center"}}>
                    <ScrollView style={style.caixa}>
                        <View style={{flexWrap: "wrap", flexDirection: "row", width: "100%", alignContent: 'center', placeContent: "center", justifyContent: "center"}}>
                            {this._renderPremis()}
                        </View>
                    </ScrollView>
                </View>

                <View style={{position: "absolute", bottom: Dimensions.get('screen').height * (13 / 15) * 0.17, right: 0}}>
                    {(this.estatSelectedItinerari && this.estatSelectedItinerari.finalitzat) ?
                    <TouchableOpacity onPress={() => {
                        if ((this.estatSelectedItinerari && this.estatSelectedItinerari.finalitzat)){
                            this.state.currentMineralLink = this.selectedItinerari.premi_alternatiu.Link
                            this.setState({carregarItinerariPerfil: this.selectedItinerari.id})
                            this.props.navigation.navigate('Mineral', this.state)
                        }
                    }} style={style.mineralContainer}>
                        <Image style={style.mineral} source={{uri: api.api_url + linkImatgeMineral}}/>
                    </TouchableOpacity>
                        :
                        <TouchableOpacity onPress={() => {}} style={style.mineralContainer}>
                            <Image style={style.mineral} source={{uri: api.api_url + linkImatgeMineral}}/>
                        </TouchableOpacity>
                    }
                </View>
            </View>)
    }

    _renderMainPage() {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: "center",}}>
                <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.18, width: "100%", justifyContent: 'center', alignItems: "center",}}>
                    <View style={style.boxPerfil}>
                        <Text style={style.textPerfil}>{this.state.recursos.find(e => e.tipus_recurs === 'perfil_nom')['text_recurs' + this.state.idioma]}</Text>
                        <Text style={style.nom}>{this.state.nom}</Text>
                    </View>
                </View>
                <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.12, width: "100%", display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: 'center'}}>
                    <View style={{width: "40%", height: "90%", justifyContent: "center"}}>
                        <View style={style.boxPuntsTotals}>
                            <Text style={style.SecondTitle}>{this.state.recursos.find(e => e.tipus_recurs === 'perfil_punts_totals')['text_recurs' + this.state.idioma]}</Text>
                            <Text style={style.SecondText}>{this.puntsTotals}</Text>

                        </View>
                    </View>
                    <View style={{width: "40%", height: "90%", justifyContent: "center"}}>
                        <View style={style.boxPuntsTotals}>
                            <Text style={style.SecondTitle}>{this.state.recursos.find(e => e.tipus_recurs === 'perfil_punts_itineraris')['text_recurs' + this.state.idioma]}</Text>
                            <Text style={style.SecondText}>{this.itinerarisCompletats}/{this.itinerarisTotals}</Text>
                        </View>
                    </View>
                </View>

                <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.50, width: "90%", alignItems: "center", justifyContent: "center"}}>
                    <ScrollView style={style.caixa}>
                        <View style={{
                            marginTop: 10,
                            marginHorizontal:20,
                            marginBottom: 30,
                            justifyContent: 'center',
                            flexGrow: 0,
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            height: "50%",
                        }}>
                            {this._renderMinerals()}
                        </View>
                        <View style={{
                            justifyContent: 'center',
                            flexGrow: 0,
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}>
                        {this._renderItineraris()}
                        </View>
                    </ScrollView>
                </View>
                <View style={{ bottom : '9%', height: Dimensions.get('screen').height * (13 / 15) * 0.09, flexDirection: "row", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                    <TouchableOpacity style={{marginLeft: 8, marginRight: 8, flexDirection: "row", flexWrap: "wrap", height: Dimensions.get('screen').height * (13 / 15) * 0.07, width: Dimensions.get('screen').height * (13 / 15) * 0.07}} onPress={() => {
                        this.props.navigation.navigate('Credits', this.state)
                    }}>
                        <Image style={{width: "100%", height: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_contacte')['imatge_recurs'].url}}/>
                    </TouchableOpacity>
                    <TouchableOpacity style={{marginLeft: 8, marginRight: 8, flexDirection: "row", flexWrap: "wrap", height: Dimensions.get('screen').height * (13 / 15) * 0.07, width: Dimensions.get('screen').height * (13 / 15) * 0.07}} onPress={() => {
                        this.setState({popUpSocial: true})
                    }}>
                        <Image style={{width: "100%", height: "100%",}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_compartir')['imatge_recurs'].url}}/>
                    </TouchableOpacity>

                </View>
                <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.1}}></View>
                {this.state.popUpSocial ? this._renderPopUpSocial() : null}
            </View>
        )
    }

    _handlePressButtonAsync = async (link) => {
        let result = await WebBrowser.openBrowserAsync(link);
    };


    _renderPopUpSocial() {
        const copyToClipboard = (copy) => {
            Clipboard.setString(copy);
        };

        return (
            <Modal animationType="slide"
                   ariaHideApp={false}
                   transparent={true}
            >
                <View style={style.modalOutBackground}>
                    <View style={style.caixaFinal}>
                        <ImageBackground
                            source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '06_background_pantalles_generic')['imatge_recurs' + this.state.idioma].url}}
                            style={style.imageBackground}>
                            <TouchableOpacity style={style.crossBox} onPress={() => {
                                this.setState({popUpSocial: false})
                            }}>
                                <Image style={style.crossImage}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                            </TouchableOpacity>
                            <View style={{flex: 2, justifyContent: "center", alignItems: "center" , width: "70%"}}>
                                 <Image style={{resizeMode: "contain", width: "100%", height: "90%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_talpo')['imatge_recurs' + this.state.idioma].url}} />
                            </View>
                            <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                                <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={async () => {
                                    this._handlePressButtonAsync("https://www.facebook.com/sharer/sharer.php?u=https://garraf.com&t=" +
                                        this.state.textSocial +
                                        " https%3A%2F%2Fjoc.garrafturisme.cat")
                                    postStadistic({token: this.state.user_token, tipus_peticio: "Compartir xarxes", peticio: "Compartir xarxes facebook (General)"})


                                }}>
                                    <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_facebook')['imatge_recurs'].url}}/>
                                </TouchableOpacity>
                            </View>
                            <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                                <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={async () => {
                                    this._handlePressButtonAsync("https://twitter.com/intent/tweet?text=" +
                                        this.state.textSocial)
                                    postStadistic({token: this.state.user_token, tipus_peticio: "Compartir xarxes", peticio: "Compartir xarxes twitter (General)"})

                                }}>
                                    <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_twitter')['imatge_recurs'].url}}/>
                                </TouchableOpacity>
                            </View>
                            <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                                <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={async () => {
                                    this._handlePressButtonAsync("whatsapp://send?text=" +
                                        this.state.textSocial +
                                    " https%3A%2F%2Fjoc.garrafturisme.cat")
                                    postStadistic({token: this.state.user_token, tipus_peticio: "Compartir xarxes", peticio: "Compartir xarxes whatsapp (General)"})

                                }}>
                                    <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_whatsapp')['imatge_recurs'].url}}/>
                                </TouchableOpacity>
                            </View>
                            <View style={{flex: 1, justifyContent: "center", alignItems: "center", flexWrap: "wrap", flexDirection: "row"}}>
                                <TextInput
                                    autoCorrect={false}
                                    //underlineColorAndroid="transparent"
                                    returnKeyType="send"
                                    onChangeText={text => this.setState({ nom: text })}
                                    style={style.textCopy}
                                    value="https://joc.garrafturisme.cat"
                                />
                                <TouchableOpacity style={{height: "37px", width: "37px", padding: 5, marginLeft: 9,backgroundColor: "white",borderTopRightRadius:5,borderBottomRightRadius:5,}} onPress={async () => {
                                    copyToClipboard("https://joc.garrafturisme.cat")
                                }}>
                                    <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'icona_copiar')['imatge_recurs'].url}}/>
                                </TouchableOpacity>
                            </View>
                        </ImageBackground>
                    </View>
                </View>
            </Modal>
        )
    }

    renderBoto(navigate, icona_url) {
        if(icona_url)
            return (
                <TouchableOpacity style={[style.menu6]} onPress={async() =>  {
                    this.setState({showItinerari: false})
                    if(navigate !== "Perfil") {
                        localStorage.setItem('currentPage', navigate)
                        this.props.navigation.navigate(navigate, this.state)
                    }
                }}>
                    <Image style={{width: 60, height: 60, alignSelf: 'center'}}
                           source={{uri: api.api_url + icona_url}}/>
                </TouchableOpacity>
            )
        else
            return null;
    }

    render() {
        return (
            <TouchableWithoutFeedback onPress={() => this.setState({visible: false})}>
                <View style={[{flex: 1}]}>
                    <View style={{flex: 15}}>
                        <ImageBackground
                            source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == '05_background_tipus4')['imatge_recurs'].url}}
                            style={style.imageBackground}>
                                {
                                    !this.state.showItinerari ?
                                        this._renderMainPage() :
                                        this._renderPremisFromItinerari()
                                }
                                {this.state.visible ? <View style={{ position: 'absolute',top: 0, bottom: 0, right: 0, left: 0, backgroundColor: 'rgba(216, 216, 216, 0.6)' }}></View> : null}
                                {this.state.SobreVisible ? <View style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, backgroundColor: 'rgba(216, 216, 216, 0.6)'}}></View> : null}
                        </ImageBackground>
                    </View>
                    <View style={{flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed',}}>
                        {/* menu hardcoded per el canvi d'estat en el perfil */}
                        <View style={[style.containerMenu, {backgroundColor: "#5f5a35"}]}>
                        {this.renderBoto('Home', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu1')['imatge_recurs'].url )}
                {this.renderBoto('Itineraris', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu3')['imatge_recurs' + this.state.idioma].url)}
                {this.renderBoto('Joc3', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu4')['imatge_recurs' + this.state.idioma].url)}
                {this.renderBoto('Credits', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu2')['imatge_recurs' + this.state.idioma].url )}
                {this.renderBoto('Perfil', this.state.recursos.find(e => e.tipus_recurs === '00_BotoMenu5')['imatge_recurs' + this.state.idioma].url )}
                 
                        </View>
                    </View>

                    {this._renderModalPremi()}
                    {this._renderModalAnimacioCarta()}
                    {this.state.popUpSocial ? this._renderPopUpSocial() : null}


                </View>
            </TouchableWithoutFeedback>
        )
    }
}
