import React from 'react';
import {SafeAreaView, FlatList, View, Text, ImageBackground, TouchableOpacity, Image, Dimensions} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import {gameId} from '../../Mapa/SampleData';
import Menu from "../../menu/menu";
import CardFlip from 'react-native-card-flip';
import { ScrollView } from 'react-native-web';

export default class Quizz extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state["showContinue"] = false;
        this.state["carregat"] = false
    }

    componentDidMount() {
        setTimeout(async function () { //Start the timer
            this.setState({carregat: true})
        }.bind(this), 300)
    }

    _goQuizz() {
        console.log("trying to go quizz")
        if(this.state.load) {
            console.log("go quizz")
            this.setState({showQuizz: true});
        }
    }

    _flipCard(id_card, valid) {
        if(this.state.carregat && !this.state.showContinue) {
            this.state["card_" + id_card].flip({duration: 700, flipDirection: 'y'})
            if (valid) this.setState({showContinue: true})
            else this.setState({puntsProva: this.state.puntsProva - 1})
        }

    }

    GoPremi() {
        this.props.navigation.navigate('Premi', this.state)
    }

    _renderAnswers() {
        return(<SafeAreaView style={style.safeAreaAnswer}>
            <FlatList
                data={this.state.poi["Quizz" + this.state.idioma].respostes}
                style={{flex: 1}}
                showsVerticalScrollIndicator ={false}
                showsHorizontalScrollIndicator={false}
                renderItem={({ item }) => (
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: "center", margin: 1 }}>
                        <CardFlip style={style.cardContainer} ref={(card) => this.state["card_" + item.id] = card} >
                            <TouchableOpacity style={style.containerAnswer} onPress={() => {
                                this._flipCard(item.id, item.valid)
                            }}>
                                {
                                    (this.state.poi.Tipus === "Quizz") ?
                                        <Text style={style.answer}>{item["answer"]}</Text>
                                    :
                                        <Image
                                            resizeMode="contain"
                                            style={style.questionImage}
                                            source={{uri: api.api_url + this.state.poi["resposta_imatge_" + item.id].url}}
                                        />
                                }
                            </TouchableOpacity>
                            <TouchableOpacity style={style.containerAnswerBack}>
                                <Image
                                    resizeMode="contain"
                                    style={style.talpSolucio}
                                    source={item.valid ? {uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'resposta_encert')['imatge_recurs'].url} : {uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'resposta_error')['imatge_recurs'].url}}
                                />
                                { (this.state.poi.Tipus === "Quizz") ?? <Text style={style.answerBack}>{item.answer + this.state.idioma}</Text> }
                            </TouchableOpacity>
                        </CardFlip>
                    </View>
                )}
                //Setting the number of column
                numColumns={2}
                keyExtractor={(item, index) => index}
            />
        </SafeAreaView>

        )
    }

    _renderContinue() {
        if (this.state.showContinue) {
            return (
                <TouchableOpacity style={style.containerContinue} onPress={() => {
                    this.GoPremi()
                }}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
        }
        else {
            return (
                <TouchableOpacity style={{visibility: 'hidden', marginTop: 20, paddingLeft: 15, paddingRight: 15, height: 50}}>
                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                </TouchableOpacity>
            )
        }
    }

    render(){
        console.log(this.state.showQuizz)
        return(
            <View style={{flex: 1}}>
                <View style={{flex: 15}}>
                    <ImageBackground source={{ uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '06_background_pantalles_generic')['imatge_recurs'].url }} style={style.imageBackground}>
                        <View style={{flex: 20, justifyContent: 'center', alignItems: 'center', width: "100%",paddingTop:Dimensions.get('screen').height * 10/250}}>
                            <View style={style.questionBackground}>
                                <ScrollView contentContainerStyle={style.scroll}>
                                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={style.question}>{this.state.poi["Quizz" + this.state.idioma].pregunta}</Text>
                                    </View>
                                </ScrollView>
                            </View>
                        </View>
                        <View style={{flex: 30, justifyContent: 'center', alignItems: 'center'}}>
                            {this._renderAnswers()}
                        </View>
                        <View style={{flex: 10, justifyContent: 'center'}}>
                            {this._renderContinue()}
                        </View>
                        <View style={{flex: 7}} />
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed' }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>

        )
    }

    /*render() {
        return (
            <View style={{flex: 1}}>
                <ImageBackground
                    source={{uri: api.api_url + this.state.projecte['FonsPantalla4' + this.state.idioma].url}}
                    style={style.imageBackground}>
                    <View style={{flex: 15}}>

                    </View>
                </ImageBackground>
            </View>
        )
    }*/
}
