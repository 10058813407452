import { StyleSheet } from 'react-native';
const colorCorporatiu = 'black';


const style = StyleSheet.create({
    fontFamily: 'Open Sans',
    textLogin:{
        fontSize: 15,
        color : colorCorporatiu,
        textAlign:'center',
        fontWeight : 'bold',
        paddingTop: 20, 
    },
    container: {
        flex: 1,
        flexDirection: "column"
      },
      imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom:15,
      },
      imatge: {
        width:200,
        height:200,
        alignSelf:'center',
        paddingTop: 30,
        resizeMode: 'contain',
      },
      titol: {
        fontFamily: 'Lobster',
        fontSize: 25,
        textAlign: "center",
        paddingTop:0,
        paddingBottom:10, 
    //    color: "white",
      },
      text: {
        fontFamily: 'OpenSans',
        color: "black",
        fontSize: 25,
        // textAlign: "justify",
        // justifyContent:'center',
        alignContent:'center',
      },
      caixaOut:{
       // backgroundColor:'rgba(116, 81, 110, 0.8)',
        marginHorizontal:'10%', 
        borderRadius:10,
        flex: 1
      },
      caixa:{
        flex: 1,
        marginHorizontal:10,
        marginVertical:10,
      },
      caixaBorderContenidor:{
        borderWidth: 3,
        borderRadius:10,
      }
});
    
export default style; 