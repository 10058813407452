import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    container: {
      flex: 1,
    },

    overlap: {
      width: 300,
      position: 'absolute',
      top: 80,
      zIndex: 9999,
      overflow: 'visible'
    },

    imageBackground: {
      flex: 1,
      resizeMode: "cover",
      justifyContent: "center",
    },
    
    imatge: {
      height: 100,
      width: 150
    },
    text: {
      color: "black",
      fontSize: 30,
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: 10,
    },

    titol: {
      paddingBottom:5,
      fontSize: 30,
        fontWeight: "bold",
        textAlign: "center",
      marginBottom: 1,
      borderTopColor:'white',
      borderTopWidth:1,
      paddingBottom:8,
      paddingTop:8,
      width:'90%',
      alignSelf:'center'
    },

    icone: {
      ...Platform.select({
        android: {
          width: 40,
          height: 40,
          marginBottom: -15,
          resizeMode: "center",
        },
        ios: {
          width: 40,
          height: 40,
          marginBottom: -15,
          resizeMode: "center",
        },
        web: {
          width: 60,
          height: 45,
          marginLeft: 15, 
          marginBottom: -15,
          resizeMode: "center",
        },
      }),
    }
  });
