import React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import style from './style';
import * as WebBrowser from 'expo-web-browser';
import api from '../config/api_urls';
import Menu from '../menu/menu';
import AsyncStorage from '@react-native-community/async-storage';

export default class Joc3 extends React.Component{

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['navigation'] = this.props.navigation;
        this.pages = (typeof this.state.projecte !== 'undefined' && typeof this.state.projecte.pagines !== 'undefined') ? this.state.projecte.pagines : [];
        this.state["selectedPage"] = {}
     //   console.log(this.pages)
    }

    componentDidMount(){
      //Serveix per redirecionar al menu o als creditsInici
     AsyncStorage.setItem('anarCredits',true);
  }

    _handlePressButtonAsync = async (link) => {
      let result = await WebBrowser.openBrowserAsync(link);
  //    console.log(result);
    };

    //style del view style={.state.projecte.vistes.length -1 === i ? {paddingBottom:50}:{paddingBottom:10}}
plataforma(){
  return(  this.state.projecte.vistes.map((data, i) => {
      return (
      <View>
         
        <TouchableOpacity onPress={()=>{this._handlePressButtonAsync(data.URL)}}>
          <View style={{textAlign: 'center'}}>
            <Image style={{ marginTop: this.state.pop_up_margin_top /7, maxHeight: this.state.pop_up_height, paddingBottom: Dimensions.get('window').width * 0.30 , marginHorizontal:'2%'  }} source={{ uri: api.api_url+ data.miniatura.url }} />
            {data.Tipus == 'Vista360' ? (<Image style={{ width: 50, height: 50, position: 'absolute', zIndex: 9999, overflow: 'visible', top: '40%', left: '40%', justifyContent: 'center', alignItems: 'center' }} source={require('../../img/360.png')} />) : null}
            {data.Tipus == 'Dron' ? (<Image style={{ width: 50, height: 50, position: 'absolute', zIndex: 9999, overflow: 'visible', top: '40%', left: '40%', justifyContent: 'center', alignItems: 'center' }} source={require('../../img/dron.png')} />) : null}
          </View>
          {data.Tipus != 'Link' ? (<Text style={[style.titol,{color: this.state.projecte.ColorCorporatiuText2}]}>{data["Titol"+this.state.idioma]}</Text>) : null}
        </TouchableOpacity>
      </View>)
    }))
  }

render(){
    return(
      <View style={style.container}>

        <ImageBackground
            source={{uri: api.api_url +  this.state.recursos.find(e => e.tipus_recurs == '05_background_tipus3')['imatge_recurs'].url}}
            style={style.imageBackground}>
          <View style={{/*marginTop: this.state.pop_up_margin_top *0.5 , maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.05*/}}>
              {/* <Text style={[style.titol,{color: this.state.projecte.ColorCorporatiuText2}]}>{this.state.recursos.find(e => e.tipus_recurs === 'centres_visites')['text_recurs' + this.state.idioma]}</Text> */}
              <ScrollView>
              { this.plataforma() }
                  <View id="pelmenu i que es vegi l'ultim" style={{height: 90}}></View>
              </ScrollView>
          </View>
          </ImageBackground>

          <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
              <Menu projecte={this.state}></Menu>
          </View>
      </View>
    )
  }
}